import {Modal} from "../modal-module";
import {GarudaApi} from "../../infrastructure/garudaApi";
import {DropdownModule, NotificationModule} from "ditmer-embla";
import {CustomTooltipModule} from "../custom-tooltip-module";
import {Localization} from "../localization-module";

export class ReportMailSendModule {
    private modal: Modal;
    private tooltipModule: CustomTooltipModule;
    private garudaApi: GarudaApi;
    private selectedEmails: string[] = [];
    private suggestedEmails: string[] = [];
    private alertTimeout: number | null = null;

    private readonly localStorageEmailsKey: string = 'Notification_Dropdown_Emails_Suggestions';
    private readonly localStorageLanguageKey: string = 'Notification_Dropdown_Email_Language_Selection';
    private readonly userLanguageInputSelector: string = '#UserLanguage';
    private readonly maxEmails: number = 5;
    private readonly sendReportModalTriggerSelector: string = "#report-status-email-icon";
    private readonly sendReportModalSelector: string = "#report-mail-send-modal";
    private readonly emailLanguageSelectorSelector: string = "#email-language-selector";
    private readonly suggestedEmailsDropdownSelector = "#suggested-emails-dropdown";
    private readonly outerCustomTooltipSelector: string = "#custom-tooltip-container";
    private readonly EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@""]+(\.[^<>()\[\]\\.,;:\s@""]+)*)|("".+""))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9øæåöäüØÆÅÖÄÜéÉèÈêÊëËçÇñÑß]+\.)+[a-zA-Z]{2,}))$/;

    constructor(garudaApi: GarudaApi) {
        this.garudaApi = garudaApi;
        this.suggestedEmails = this.getEmailsFromLocalStorage();
    }

    public initModal(reportId: string, reportName: string): void {
        this.modal = new Modal(
            this.sendReportModalTriggerSelector,
            this.sendReportModalSelector,
            () => this.garudaApi.getReportMailModal(reportId),
            () => {
                this.initEvents();
                this.clearSelectedEmails();
                this.initCustomTooltip("#custom-tooltip-container-mail-report");
            },
            () => this.applyModal(reportId, reportName)
        );

        this.modal.init();
        this.bindOnModalClose();
    }

    public async showModalContent(): Promise<void> {
        await this.modal.loadModalContent();
        this.modal.open();
    }

    private initCustomTooltip = (tooltipSelector: string): void => {
        this.tooltipModule = new CustomTooltipModule({
            tooltipContainerIdSelector: tooltipSelector
        });
        this.tooltipModule.init();
    }

    private initEvents(): void {
        new DropdownModule().init(this.emailLanguageSelectorSelector, {
            allowClear: false,
            searchable: false,
        });
        
        const storedLanguage = this.getStoredLanguage();
        
        if (storedLanguage) {
            $(this.emailLanguageSelectorSelector).val(storedLanguage).trigger('change');
        }
    
        $(this.emailLanguageSelectorSelector).on('change', (e) => {
            const selectedLanguage = $(e.target).val() as string;
            this.saveLanguageToLocalStorage(selectedLanguage);
        });

        this.initEmailDropdown();
    }

    private initEmailDropdown(): void {
        const $dropdown = $(this.suggestedEmailsDropdownSelector);
        $dropdown.off();

        this.initializeDropdownWithOptions();
        this.bindDropdownEvents($dropdown);
        $dropdown.val([]).trigger('change');
    }

    private initializeDropdownWithOptions(): void {
        const emailOptions = this.suggestedEmails.map(email => ({
            id: email,
            text: email
        }));

        new DropdownModule().init(this.suggestedEmailsDropdownSelector, {
            allowClear: true,
            additionalSelect2Options: {
                data: emailOptions,
                placeholder: "",
                tags: true,
                multiple: true,
            }
        });
    }

    private bindDropdownEvents($dropdown: JQuery): void {
        $dropdown
            .on('select2:selecting', (e: any) => this.handleEmailSelecting(e))
            .on('select2:select', (e: any) => this.handleEmailSelect(e, $dropdown))
            .on('select2:unselect', () => this.handleEmailUnselect($dropdown));
    }

    private handleEmailSelecting(e: any): void {
        const email = e.params.args.data.text;

        if (!this.isValidEmail(email)) {
            this.showError(Localization.getText("Global_Email_Invalid"));
            e.preventDefault();
            return;
        }
    }

    private handleEmailSelect(e: any, $dropdown: JQuery): void {
        const values = $dropdown.val() as string[];
        const email = e.params.data.text;

        if (values.length > this.maxEmails) {
            this.showError(Localization.getText("ReportMailMaximum"));
            values.splice(values.indexOf(email), 1);
            $dropdown.val(values).trigger('change');
            return;
        }

        this.selectedEmails = values;
        this.saveEmailsToLocalStorage();
    }

    private handleEmailUnselect($dropdown: JQuery): void {
        this.selectedEmails = $dropdown.val() as string[];
    }

    private refreshDropdownOptions(): void {
        this.suggestedEmails = this.getEmailsFromLocalStorage();
        $(this.suggestedEmailsDropdownSelector).select2('destroy');
        this.initEmailDropdown();
        $(this.suggestedEmailsDropdownSelector).val(this.selectedEmails).trigger('change');
    }

    private bindOnModalClose = (): void => {
        const $modal = $(this.sendReportModalSelector);
        $modal.off('hidden.bs.modal').on('hidden.bs.modal', (e) => {
            $(e.currentTarget).find(".modal-body").empty();
            this.clearAlertTimeout();
            this.initCustomTooltip(this.outerCustomTooltipSelector);
            $(this.suggestedEmailsDropdownSelector).select2('destroy');
            this.clearSelectedEmails();
        });
    }

    private clearSelectedEmails() {
        this.selectedEmails = [];
    }

    private async applyModal(reportId: string, reportName: string): Promise<void> {
        this.modal.showLoader();

        const selectedLanguage = $(this.emailLanguageSelectorSelector).val() as string;

        if (this.selectedEmails.length === 0) {
            this.showError(Localization.getText("ReportMailMinimum"));
            this.modal.hideLoader();
            return;
        }

        try {
            await this.garudaApi.requestSendReportMail(reportId, this.selectedEmails, selectedLanguage, reportName);

            NotificationModule.showSuccessSmall(Localization.getText("ReportMailSuccess"));
            this.clearSelectedEmails();
            this.modal.close();
        } catch (error) {
            // Do nothing here as error toast is shown
            // Does not close modal and does not clear email list
        } finally {
            this.modal.hideLoader();
        }
    }

    private getStoredLanguage(): string {
        try {
            const defaultLanguage = $(this.userLanguageInputSelector).val() as string;
            console.log("defaultLanguage", defaultLanguage);
            return localStorage.getItem(this.localStorageLanguageKey) ?? defaultLanguage;
        } catch (e) {
            return $(this.userLanguageInputSelector).val() as string;
        }
    }
    
    private saveLanguageToLocalStorage(language: string): void {
        localStorage.setItem(this.localStorageLanguageKey, language);
    }

    private saveEmailsToLocalStorage() {
        const existingEmails = this.getEmailsFromLocalStorage();
        const allEmails = Array.from(new Set([...existingEmails, ...this.selectedEmails]));
        localStorage.setItem(this.localStorageEmailsKey, JSON.stringify(allEmails));
        this.refreshDropdownOptions();
    }

    private getEmailsFromLocalStorage(): string[] {
        try {
            const savedEmails = localStorage.getItem(this.localStorageEmailsKey);
            const emails = savedEmails ? JSON.parse(savedEmails) : [];
            return emails;
        } catch (e) {
            return [];
        }
    }

    private clearAlertTimeout(): void {
        if (this.alertTimeout) {
            clearTimeout(this.alertTimeout);
            this.alertTimeout = null;
        }
    }

    private showError(message: string): void {
        const alertHtml = `<div class="alert alert-danger margin-top-l" role="alert">${message}</div>`;
        this.showAlert(alertHtml);
    }

    private showAlert(alertHtml: string): void {
        const modalBody = $(`${this.sendReportModalSelector} .modal-body`);

        modalBody.find('.alert').remove();

        modalBody.append(alertHtml);

        if (this.alertTimeout) {
            clearTimeout(this.alertTimeout);
        }

        this.alertTimeout = window.setTimeout(() => {
            modalBody.find('.alert').fadeOut('slow', function () {
                $(this).remove();
            });
            this.alertTimeout = null;
        }, 3000) as unknown as number;
    }

    private isValidEmail(email: string): boolean {
        // This regex validates email addresses with the following rules:
        // 1. Local part (before @):
        //    - Can contain any character except <>()[]\\.,;:\s@"
        //    - Can include dots, but not consecutively
        //    - Can be a quoted string (allowing special characters)
        // 2. Domain part (after @):
        //    - Can be an IP address in square brackets
        //    - Or a series of alphanumeric labels separated by dots
        //    - The last label must be at least 2 characters long
        return this.EMAIL_REGEX.test(email.toLowerCase());
    }
}
